enum AppRoutes {
  WELCOME = "/",
  PHOEG = "/phoeg",
  TUTORIAL = "/tutorial",
  AUTOCONJECTURES = "/autoconjectures",
  AUTOCONJECTURES_PRINT = "/autoconjectures/print",
  INFORMATIONS = "/infos",
  ABOUT = "/about",
}

export default AppRoutes;
