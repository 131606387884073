import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useReducer, useState } from "react";
import { Invariant } from "../phoeg_app/PolytopesSlider";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SendIcon from "@mui/icons-material/Send";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/system";
import Title from "../styles_and_settings/Title";
import MainContext from "../../store/contexts/main_context";
import CalculateIcon from "@mui/icons-material/Calculate";
import { blueGrey } from "@mui/material/colors";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { InvariantTypes } from "../phoeg_app/PhoegApp";
import { initialMainState } from "../../store/reducers/main_reducer";
import { stat } from "fs";

enum ConstraintTypes {
  NUMBER = "number",
  BOOL = "bool",
  SPECIAL = "special",
  ADVANCED = "advanced", // For advanced constraints mode
  NONE = "none", // For no constraint selected
}

export interface FormProps {
  invariants: Array<Invariant>;
  withOrders: boolean;
  withConcave?: boolean;
  setWithConcave?: (value: boolean) => void;
}

enum ConstraintAction {
  ADD_CONSTRAINT,
  REMOVE_CONSTRAINT,
  CHANGE_NAME,
  CHANGE_MIN,
  CHANGE_MAX,
  CHANGE_ADVANCED_MODE,
  CHANGE_ADVANCED_FIELD,
  RESET_CONSTRAINTS,
  ERASE_NAME,
  SET,
}

export interface Constraint {
  id: number;
  name: string;
  tablename: string;
  min: number;
  max: number;
  type: ConstraintTypes;
  advancedMode: boolean;
  advancedField: string;
}

const initialConstraint = (id: number): Constraint => {
  return {
    id: id,
    name: "",
    tablename: "",
    min: 0,
    max: 0,
    type: ConstraintTypes.NONE,
    advancedMode: false,
    advancedField: "",
  };
};

enum OrdersAction {
  MIN,
  MAX,
  STEP,
  FIELD,
}

interface Orders {
  min: number;
  max: number;
  step: number;
  field: string;
}

const initialOrders = {
  min: 1,
  max: 9,
  step: 1,
  field: "",
};

const parseOrders = (orders: string): Array<number> => {
  const orders_array = orders.split(",");
  const orders_int = orders_array.map((order) => parseInt(order));
  return orders_int;
};

const antiParseOrders = (orders: Array<number>): string => {
  let orders_string = "";
  orders.forEach((order) => {
    orders_string += order.toString() + ",";
  });
  orders_string = orders_string.slice(0, -1);
  return orders_string;
};

const isNumericalInvariant = (type: string): boolean => {
  return (
    type === InvariantTypes.NUMBER ||
    type === InvariantTypes.INTEGER ||
    type === InvariantTypes.REAL ||
    type === InvariantTypes.DOUBLE
  );
};

const isColorationInvariant = (type: string): boolean => {
  return (
    type === InvariantTypes.BOOLEAN ||
    type === InvariantTypes.NUMBER ||
    type === InvariantTypes.INTEGER ||
    type === InvariantTypes.REAL ||
    type === InvariantTypes.DOUBLE ||
    type === InvariantTypes.SPECIAL
  );
};

const convertToConstraintType = (type: string): ConstraintTypes => {
  if (type === InvariantTypes.BOOLEAN) {
    return ConstraintTypes.BOOL;
  } else if (type === InvariantTypes.SPECIAL) {
    return ConstraintTypes.SPECIAL;
  } else {
    return ConstraintTypes.NUMBER;
  }
};

const Form = ({
  invariants,
  withOrders, // If true autoconjecture app, else phoeg app
  withConcave, // If true phoeg app with concave, else phoeg app
  setWithConcave,
}: FormProps) => {
  const HEIGHTCARD = withOrders ? 125 : 100;
  const HEIGHTCARDCONSTRAINT = 200;

  const [showForm, setShowForm] = useState(true);

  const mainContext = useContext(MainContext);

  const [openModal, setOpenModal] = useState(false);
  const [previousOrders, setPreviousOrders] = useState(""); // To save previous orders if user cancel

  const [invWithRat, setInvWithRat] = useState<Array<Invariant>>([]); // Sorted invariants with rational (remove duplicates)
  const [invWithoutRat, setInvWithoutRat] = useState<Array<Invariant>>([]); // Sorted invariants without rational
  const [invColoration, setInvColoration] = useState<Array<Invariant>>([]); // Sorted invariants for coloration (number, bool, special)

  const [currentId, setCurrentId] = useState(1); // Unique id for each item (not change if add or remove item)

  const [showColoration, setShowColoration] = useState(false);
  const [showConstrSubForm, setShowConstrSubForm] = useState(false);

  const constraintsInvariant: Array<Invariant> = invariants.filter(
    // Filter invariants to keep only invariants can be constraint
    (inv: Invariant) => isColorationInvariant(inv.datatype)
  );

  const getConstraintFromName = (name: string): Invariant => {
    return constraintsInvariant.find((inv) => inv.name === name) as Invariant;
  };

  // Constraints reducer
  const constraintsReducer = (state: any, action: any) => {
    switch (action.type) {
      case ConstraintAction.REMOVE_CONSTRAINT:
        const new_state = state.filter(
          (item: Constraint) => item.id !== action.id
        );
        return new_state;

      case ConstraintAction.ADD_CONSTRAINT:
        let previous = state;
        previous.push(initialConstraint(currentId));
        return previous;

      case ConstraintAction.CHANGE_NAME:
        const new_constraint = getConstraintFromName(action.name as string);

        const new_state_name = state.map((item: Constraint) => {
          if (item.id === action.id) {
            item.name = new_constraint.name;
            item.tablename = new_constraint.tablename;
            item.type = convertToConstraintType(new_constraint.datatype);
            item.min = item.type === ConstraintTypes.BOOL ? 1 : 0;
            item.max = 1;
          }
          return item;
        });
        return new_state_name;

      case ConstraintAction.CHANGE_MIN:
        const new_state_min = state.map((item: Constraint) => {
          if (item.id === action.id) {
            item.min = parseFloat(action.min);
          }
          return item;
        });
        return new_state_min;

      case ConstraintAction.CHANGE_MAX:
        const new_state_max = state.map((item: Constraint) => {
          if (item.id === action.id) {
            item.max = parseFloat(action.max);
          }
          return item;
        });
        return new_state_max;

      case ConstraintAction.CHANGE_ADVANCED_MODE:
        const new_state_advanced_mode = state.map((item: Constraint) => {
          if (item.id === action.id) {
            item.advancedMode = action.advancedMode;
            item.type = action.newType;
          }
          return item;
        });
        return new_state_advanced_mode;

      case ConstraintAction.CHANGE_ADVANCED_FIELD:
        const new_state_advanced = state.map((item: Constraint) => {
          if (item.id === action.id) {
            item.advancedField = action.advancedField;
          }
          return item;
        });
        return new_state_advanced;

      case ConstraintAction.RESET_CONSTRAINTS:
        setCurrentId(1);
        return [initialConstraint(1)];

      case ConstraintAction.ERASE_NAME:
        const new_state_erase_name = state.map((item: Constraint) => {
          if (item.id === action.id) {
            item.name = "";
            item.type = ConstraintTypes.NONE;
          }
          return item;
        });
        return new_state_erase_name;

      case ConstraintAction.SET:
        return action.constraints;

      default:
        throw new Error();
    }
  };

  const [constraints, dispatchConstraints] = useReducer(constraintsReducer, [
    initialConstraint(1),
  ]);

  useEffect(() => {
    if (mainContext.concaves !== initialMainState.concaves) {
      stateOrders.field = antiParseOrders(mainContext.orders);
      setShowForm(false);
      if (mainContext.constraints !== initialMainState.constraints) {
        dispatchConstraints({
          type: ConstraintAction.SET,
          constraints: antiEncodeConstraints(mainContext.constraints),
        });
        setShowConstrSubForm(true);
      }
    }

    // Sort invariants by type
    const viewedRat: Array<string> = [];
    invariants.forEach((inv) => {
      if (inv.datatype === InvariantTypes.RATIONAL) {
        viewedRat.push(inv.tablename.replace("_rational", ""));
      }
    });

    const invWithRat: Array<Invariant> = [];
    invariants.forEach((inv) => {
      if (inv.datatype === InvariantTypes.RATIONAL) {
        invWithRat.push(inv);
      } else if (isNumericalInvariant(inv.datatype)) {
        if (!viewedRat.includes(inv.tablename)) {
          invWithRat.push(inv);
        }
      }
    });

    const invWithoutRat: Array<Invariant> = invariants.filter((inv) => {
      return isNumericalInvariant(inv.datatype);
    });

    const invColoration: Array<Invariant> = invariants.filter((inv) => {
      return isColorationInvariant(inv.datatype);
    });

    setInvWithRat(invWithRat);
    setInvWithoutRat(invWithoutRat);
    setInvColoration(invColoration);
  }, [invariants]);

  const handleOpen = () => {
    setPreviousOrders(stateOrders.field);
    setOpenModal(true);
  };

  const handleClose = (isCancel: boolean) => {
    if (isCancel) {
      dispatchOrders({
        type: OrdersAction.FIELD,
        field: previousOrders,
      });
    }
    setOpenModal(false);
  };

  // Orders reducer
  const ordersReducer = (state: Orders, action: any) => {
    switch (action.type) {
      case OrdersAction.MIN:
        return { ...state, min: +action.min }; // +action.min to convert string to number (if not, it's maybe a string)
      case OrdersAction.MAX:
        return { ...state, max: +action.max };
      case OrdersAction.STEP:
        return { ...state, step: +action.step };
      case OrdersAction.FIELD:
        return { ...state, field: action.field };
      default:
        return state;
    }
  };

  const [stateOrders, dispatchOrders] = useReducer(
    ordersReducer,
    initialOrders
  );

  // Orders controllers
  const handleOrdersMin = (event: any) => {
    mainContext.reset();
    dispatchOrders({ type: OrdersAction.MIN, min: event.target.value });
  };

  const handleOrdersMax = (event: any) => {
    mainContext.reset();
    dispatchOrders({ type: OrdersAction.MAX, max: event.target.value });
  };

  const handleOrdersStep = (event: any) => {
    mainContext.reset();
    dispatchOrders({ type: OrdersAction.STEP, step: event.target.value });
  };

  const handleOrdersField = (event: any) => {
    mainContext.reset();
    dispatchOrders({ type: OrdersAction.FIELD, field: event.target.value });
  };

  const submitOrders = () => {
    const orders: Array<number> = [];
    for (let i = stateOrders.min; i <= stateOrders.max; i += stateOrders.step) {
      orders.push(i);
    }
    dispatchOrders({ type: OrdersAction.FIELD, field: orders.join(", ") });
  };

  // Constraints controllers
  const handleChangeName = (id: number, name: string) => {
    mainContext.reset();
    if (name === "") {
      dispatchConstraints({ type: ConstraintAction.ERASE_NAME, id });
      return;
    }
    dispatchConstraints({ type: ConstraintAction.CHANGE_NAME, id, name });
  };

  const handleChangeMin = (id: number, min: string) => {
    mainContext.reset();
    dispatchConstraints({ type: ConstraintAction.CHANGE_MIN, id, min });
  };

  const handleChangeMax = (id: number, max: string) => {
    mainContext.reset();
    dispatchConstraints({ type: ConstraintAction.CHANGE_MAX, id, max });
  };

  const handleActiveConstraint = (id: number, previous: number) => {
    mainContext.reset();
    dispatchConstraints({
      type: ConstraintAction.CHANGE_MIN,
      id,
      min: previous === 0 ? 1 : 0,
    });
    dispatchConstraints({
      type: ConstraintAction.CHANGE_MAX,
      id,
      max: previous === 0 ? 1 : 0,
    });
  };

  const handleAddConstraint = () => {
    mainContext.reset();
    setCurrentId((prev) => prev + 1);
    dispatchConstraints({ type: ConstraintAction.ADD_CONSTRAINT });
  };

  const handleRemoveConstraint = (id: number) => {
    mainContext.reset();
    dispatchConstraints({ type: ConstraintAction.REMOVE_CONSTRAINT, id });
  };

  const resetConstraints = () => {
    mainContext.reset();
    dispatchConstraints({ type: ConstraintAction.RESET_CONSTRAINTS });
  };

  const handleAdvancedMode = (id: number, previousMode: boolean) => {
    mainContext.reset();
    dispatchConstraints({
      type: ConstraintAction.CHANGE_ADVANCED_MODE,
      id: id,
      advancedMode: !previousMode,
      newType: previousMode ? ConstraintTypes.NONE : ConstraintTypes.ADVANCED,
    });
  };

  const handleChangeAdvancedField = (id: number, field: string) => {
    mainContext.reset();
    dispatchConstraints({
      type: ConstraintAction.CHANGE_ADVANCED_FIELD,
      id,
      advancedField: field,
    });
  };

  const typeFromName = (name: string) => {
    let result = "";
    invariants.forEach((invariant) => {
      if (invariant.name === name) {
        result = invariant.datatype;
      }
    });
    return result;
  };

  // Main controllers (axis, coloration and concave hull)
  const handleChangeX = (value: string) => {
    mainContext.reset();
    mainContext.setLabelX(value, typeFromName(value));
  };

  const handleChangeY = (value: string) => {
    mainContext.reset();
    mainContext.setLabelY(value, typeFromName(value));
  };

  const handleChangeColoration = (value: string) => {
    mainContext.reset();
    mainContext.setLabelColor(value);
  };

  const handleExchangeXY = () => {
    mainContext.reset();
    const labelY = mainContext.labelY;
    mainContext.setLabelY(mainContext.labelX, mainContext.typeX);
    mainContext.setLabelX(labelY, typeFromName(labelY));
  };

  const handleShowColoration = (value: boolean) => {
    mainContext.reset();
    setShowColoration(value);
  };

  const handleChangeWithConcave = (value: boolean) => {
    mainContext.reset();
    if (setWithConcave) setWithConcave(value);
  };

  const checkAutoconjApp = () => {
    if (stateOrders.field === "") {
      return "Please enter a list of orders";
    }
    if (
      mainContext.typeX === "rational" &&
      (mainContext.typeY === "real" || mainContext.typeY === "number")
    ) {
      return "please not choose a rational type for the x axis and a real type for the y axis";
    }
    if (
      (mainContext.typeX === "real" || mainContext.typeX === "number") &&
      mainContext.typeY === "rational"
    ) {
      return "please not choose a real type for the x axis and a rational type for the y axis";
    }
    const regexOrders = /(\d(, *\d)*)/g;
    if (!regexOrders.test(stateOrders.field)) {
      return "Please enter a valid list of orders \n ex: 1, 2, 3 or 1,2,3 or 1, 2,3";
    }
    return "";
  };

  // Submit controllers and annex functions
  const handleSubmit = () => {
    if (
      mainContext.labelX === null ||
      mainContext.labelY === null ||
      mainContext.labelX === "" ||
      mainContext.labelY === ""
    ) {
      alert("Please complete all the required fields");
      return;
    }

    const messageError = checkMainDifferent();
    if (messageError !== "") {
      alert(messageError);
      return;
    }

    if (withOrders) {
      const messageErrorAutoconj = checkAutoconjApp();
      if (messageErrorAutoconj !== "") {
        alert(messageErrorAutoconj);
        return;
      } else {
        mainContext.setOrders(parseOrders(stateOrders.field));
      }
    }

    setShowForm(false);
    let { encodedConstraints, encodedAdvanced } = encodeConstraints();
    mainContext.setConstraints(encodedConstraints);
    mainContext.setAdvancedConstraints(encodedAdvanced);
    mainContext.setIsSubmit(true);
  };

  // TODO: add a check for the advanced mode and separete the advanced mode from the normal mode **
  const encodeConstraints = () => {
    let encodedConstraints = "";
    let encodedAdvanced = "";

    constraints.forEach((constraint: Constraint) => {
      if (
        constraint.type !== ConstraintTypes.NONE &&
        constraint.type !== ConstraintTypes.ADVANCED &&
        constraint.name !== ""
      ) {
        encodedConstraints += constraint.tablename + " ";
        encodedConstraints += constraint.min + " ";
        encodedConstraints += constraint.max + ";";
      } else if (constraint.advancedMode) {
        encodedAdvanced += constraint.advancedField + ";";
      }
    });
    return { encodedConstraints, encodedAdvanced };
  };

  const getNameFromTableName = (tablename: string) => {
    let result = "";
    invariants.forEach((invariant) => {
      if (invariant.tablename === tablename) {
        result = invariant.name;
      }
    });
    return result;
  };

  const constraintTypeFromName = (tableName: string) => {
    let result: ConstraintTypes = ConstraintTypes.NONE;
    invariants.forEach((inv) => {
      if (inv.tablename === tableName) {
        result = convertToConstraintType(inv.datatype);
      }
    });
    return result;
  };

  const antiEncodeConstraints = (encodedConstraints: string) => {
    let constraints: Constraint[] = [];
    let constraintsArray = encodedConstraints.split(";");
    let id = 0;
    constraintsArray.forEach((constraint) => {
      if (constraint !== "") {
        let constraintArray = constraint.split(" ");
        if (constraintArray.length === 3) {
          constraints.push({
            id: id,
            name: getNameFromTableName(constraintArray[0]),
            tablename: constraintArray[0],
            min: parseInt(constraintArray[1]),
            max: parseInt(constraintArray[2]),
            type: constraintTypeFromName(constraintArray[0]),
            advancedMode: false,
            advancedField: "",
          });
        } else if (constraintArray.length === 1) {
          constraints.push({
            id: id,
            name: "",
            tablename: "",
            min: 0,
            max: 0,
            type: ConstraintTypes.ADVANCED,
            advancedMode: true,
            advancedField: constraintArray[0],
          });
        }
        id++;
      }
    });
    setCurrentId(id);
    return constraints;
  };

  const checkMainDifferent = () => {
    if (mainContext.labelX === mainContext.labelY) {
      return "The main labels must be different (X and Y)";
    } else if (showColoration) {
      if (mainContext.labelX === mainContext.labelColor) {
        return "The main labels must be different (X and Color)";
      } else if (mainContext.labelY === mainContext.labelColor) {
        return "The main labels must be different (Y and Color)";
      }
    }
    return "";
  };

  // Syntax for the problem definition
  const constructProblemDefinition = () => {
    let problemDefinition = "";
    problemDefinition += mainContext.labelX;
    problemDefinition += " ╳ ";
    problemDefinition += mainContext.labelY;
    if (withOrders) {
      problemDefinition += " (orders: ";
      problemDefinition += stateOrders.field;
      problemDefinition += ")";
    }
    if (showColoration) {
      problemDefinition += " (";
      problemDefinition += mainContext.labelColor;
      problemDefinition += ")";
    }
    if (mainContext.constraints !== "") {
      problemDefinition += " || ";
      problemDefinition += mainContext.constraints;
    }
    if (mainContext.advancedConstraints !== "") {
      problemDefinition += " // ";
      problemDefinition += mainContext.advancedConstraints;
    }
    return problemDefinition;
  };

  // Render
  return (
    <>
      <form>
        <Title title="Problem definition" />
        <Stack
          sx={{ mt: 1, mb: 1 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Button
            variant={showForm ? "contained" : "outlined"}
            onClick={() => {
              setShowForm((prev) => !prev);
            }}
            color="success"
          >
            {showForm ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
          <Typography variant="body1">
            {showForm ? "Hide problem definition" : "Show problem definition"}
          </Typography>
          {mainContext.isSubmit && !showForm && (
            <Box sx={{ ml: 2 }}>
              <Typography variant="body1" fontStyle="italic" fontSize={12}>
                {constructProblemDefinition()}
              </Typography>
            </Box>
          )}
        </Stack>
        <Collapse in={showForm} sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            {/* X CARD */}
            <Grid item xs={3.9}>
              <Paper elevation={3} sx={{ p: 1, height: HEIGHTCARD }}>
                <Box sx={{ height: 40 }}>
                  <Typography variant="h6" align="center">
                    X-Axis*
                  </Typography>
                </Box>
                <Autocomplete
                  id="combo-box-demo"
                  sx={{ m: 1 }}
                  value={mainContext.labelX}
                  onChange={(event, newValue) =>
                    handleChangeX(newValue as string)
                  }
                  options={
                    withOrders
                      ? invWithRat.map((inv) => inv.name)
                      : invWithoutRat.map((inv) => inv.name)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invariant X" />
                  )}
                  size="small"
                />
              </Paper>
            </Grid>
            {/* EXCHANGE BUTTON */}
            <Grid item xs={0.3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={HEIGHTCARD}
                onClick={handleExchangeXY}
                sx={{ cursor: "pointer" }}
              >
                <SyncAltIcon />
              </Box>
            </Grid>
            {/* Y CARD */}
            <Grid item xs={3.9}>
              <Paper elevation={3} sx={{ p: 1, height: HEIGHTCARD }}>
                <Box sx={{ height: 40 }}>
                  <Typography variant="h6" align="center">
                    Y-Axis*
                  </Typography>
                </Box>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={mainContext.labelY}
                  onChange={(event, newValue) =>
                    handleChangeY(newValue as string)
                  }
                  options={
                    withOrders
                      ? invWithRat.map((inv) => inv.name)
                      : invWithoutRat.map((inv) => inv.name)
                  }
                  sx={{ m: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Invariant Y" />
                  )}
                  size="small"
                />
              </Paper>
            </Grid>
            {/* ORDERS CARD (autoconjecture app) OR COLOR CARD (phoeg app) */}
            <Grid item xs={3.9}>
              {withOrders ? (
                <Paper elevation={3} sx={{ p: 1, height: HEIGHTCARD }}>
                  <Box sx={{ height: 40 }}>
                    <Typography variant="h6" align="center">
                      Orders*
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      sx={{ m: 1, width: "90%" }}
                      id="list"
                      label="Orders list"
                      value={stateOrders.field}
                      onChange={handleOrdersField}
                      size="small"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={handleOpen}>
                      <Typography
                        variant="body1"
                        fontSize={10}
                        color={blueGrey[800]}
                      >
                        Generate automatically
                      </Typography>
                    </Button>
                  </Box>
                  <Modal
                    open={openModal}
                    onClose={() => handleClose(true)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: 500,
                        bgcolor: "whitesmoke",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        p: 2,
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ textAlign: "center" }}
                      >
                        Fill the fields to generate the orders automatically and
                        change this if necessary
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          sx={{ m: 1, width: "100px" }}
                          id="min"
                          label="Min"
                          type="number"
                          InputProps={{
                            inputProps: { min: 1, max: 10 },
                          }}
                          value={stateOrders.min}
                          onChange={handleOrdersMin}
                          size="small"
                        />
                        <TextField
                          sx={{ m: 1, width: "100px" }}
                          id="max"
                          label="Max"
                          type="number"
                          InputProps={{
                            inputProps: { min: 1, max: 10 },
                          }}
                          value={stateOrders.max}
                          onChange={handleOrdersMax}
                          size="small"
                        />
                        <TextField
                          sx={{ m: 1, width: "100px" }}
                          id="step"
                          label="Step"
                          type="number"
                          InputProps={{
                            inputProps: { min: 1, max: 10 },
                          }}
                          value={stateOrders.step}
                          onChange={handleOrdersStep}
                          size="small"
                        />
                        <Button
                          sx={{ m: 1, width: "25px" }}
                          variant="contained"
                          onClick={submitOrders}
                          color="success"
                          size="small"
                        >
                          <CalculateIcon />
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          sx={{ m: 1, width: "410px" }}
                          id="list"
                          label="Orders list"
                          value={stateOrders.field}
                          onChange={handleOrdersField}
                        />
                      </Box>
                      <Box
                        sx={{
                          m: 1,
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          sx={{ m: 1 }}
                          variant="outlined"
                          onClick={() => {
                            handleClose(true);
                          }}
                          size="small"
                          color="warning"
                          startIcon={<CloseIcon />}
                        >
                          <Typography
                            variant="body1"
                            fontSize={10}
                            color={blueGrey[800]}
                          >
                            Cancel
                          </Typography>
                        </Button>
                        <Button
                          sx={{ m: 1 }}
                          variant="outlined"
                          onClick={() => {
                            handleClose(false);
                          }}
                          size="small"
                          color="success"
                          endIcon={<DoneIcon />}
                        >
                          <Typography
                            variant="body1"
                            fontSize={10}
                            color={blueGrey[800]}
                          >
                            Valid
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </Paper>
              ) : (
                // COLOR CARD
                <Paper elevation={3} sx={{ p: 1, height: HEIGHTCARD }}>
                  <Box sx={{ height: 40 }}>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        color: showColoration
                          ? "text.primary"
                          : "text.disabled",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShowColoration(!showColoration)}
                    >
                      <Checkbox
                        checked={showColoration}
                        size="small"
                        color="success"
                      />
                      Coloration
                    </Typography>
                  </Box>
                  <Autocomplete
                    disabled={!showColoration}
                    disablePortal
                    id="combo-box-demo"
                    value={mainContext.labelColor}
                    onChange={(event, newValue) =>
                      handleChangeColoration(newValue as string)
                    }
                    options={invColoration.map((option) => option.name)}
                    sx={{ m: 1 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Invariant color" />
                    )}
                    size="small"
                  />
                </Paper>
              )}
            </Grid>
          </Grid>

          {/* Concave hull option only in PHOEG app */}
          {withConcave !== undefined && setWithConcave !== undefined && (
            <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
              <Typography variant="body1">
                Do you want compute and show Pareto hull on your polytope(s) ?
              </Typography>
              <Switch
                checked={withConcave}
                onChange={(event) => {
                  handleChangeWithConcave(event.target.checked);
                }}
                color="success"
                size="small"
                sx={{ ml: 1 }}
              />
            </Box>
          )}

          {/* CONSTRAINTS SUB FORM */}
          {showConstrSubForm ? (
            // Constraints mode
            <Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Tooltip title="Warning! Reset constraints" placement="top">
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={
                      showConstrSubForm ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                    onClick={() => {
                      resetConstraints();
                      setShowConstrSubForm(!showConstrSubForm);
                    }}
                  >
                    {showConstrSubForm ? "Hide" : "Show"} constraints
                  </Button>
                </Tooltip>
                <Button
                  variant="outlined"
                  color="success"
                  endIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddConstraint}
                >
                  Add constraint
                </Button>
              </Box>

              <Grid container spacing={2}>
                {constraints.map((constraint: Constraint, index: number) => (
                  <Grid item xs={4} key={constraint.id}>
                    <Paper
                      elevation={1}
                      sx={{ p: 1, height: HEIGHTCARDCONSTRAINT }}
                    >
                      <Box
                        sx={{
                          height: 40,
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography variant="h6" align="center">
                          {constraint.advancedMode ? "Advanced" : "Simple"}{" "}
                          constraint #{index + 1}
                        </Typography>
                        <Tooltip title="Remove this constraint">
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleRemoveConstraint(constraint.id)
                            }
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleAdvancedMode(
                              constraint.id,
                              constraint.advancedMode
                            )
                          }
                        >
                          <Typography variant="body1" fontSize={10}>
                            Change to{" "}
                            {constraint.advancedMode ? "simple " : "advanced "}
                          </Typography>
                        </Button>
                      </Box>
                      {constraint.advancedMode ? (
                        // Advanced mode
                        <Box>
                          <TextField
                            sx={{ ml: 1, mr: 1, mt: 1, width: "95%" }}
                            label="Enter your expression"
                            multiline
                            rows="4"
                            onChange={(event) =>
                              handleChangeAdvancedField(
                                constraint.id,
                                event.target.value
                              )
                            }
                            size="small"
                          />
                        </Box>
                      ) : (
                        // Simple mode
                        <Box>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={constraint.name}
                            onChange={(event, newValue) =>
                              handleChangeName(
                                constraint.id,
                                newValue === null ? "" : newValue
                              )
                            }
                            options={constraintsInvariant.map(
                              (inv) => inv.name
                            )}
                            sx={{ m: 1 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Invariant" />
                            )}
                            size="small"
                          />
                          {constraint.type !== ConstraintTypes.NONE ? (
                            constraint.type === ConstraintTypes.BOOL ? (
                              // Bool constraint
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Switch
                                  checked={constraint.min === 1}
                                  onChange={() => {
                                    handleActiveConstraint(
                                      constraint.id,
                                      constraint.min
                                    );
                                  }}
                                  color="success"
                                  size="small"
                                />
                              </Box>
                            ) : (
                              // Number constraint
                              <Box
                                sx={{
                                  m: 1,
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <TextField
                                  sx={{ m: 1, width: "50%" }}
                                  id="min"
                                  label="Min"
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 0, max: constraint.max },
                                  }}
                                  value={constraint.min}
                                  onChange={(e) =>
                                    handleChangeMin(
                                      constraint.id,
                                      e.target.value
                                    )
                                  }
                                  size="small"
                                />
                                <TextField
                                  sx={{ m: 1, width: "50%" }}
                                  id="max"
                                  label="Max"
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: constraint.min },
                                  }}
                                  value={constraint.max}
                                  onChange={(e) =>
                                    handleChangeMax(
                                      constraint.id,
                                      e.target.value
                                    )
                                  }
                                  size="small"
                                />
                              </Box>
                            )
                          ) : null}
                        </Box>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<SendIcon />}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          ) : (
            // Submit mode
            <Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={
                    showConstrSubForm ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  onClick={() => setShowConstrSubForm(!showConstrSubForm)}
                >
                  {showConstrSubForm ? "Hide" : "Show"} constraints
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<SendIcon />}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </Collapse>
      </form>
    </>
  );
};

export default Form;
